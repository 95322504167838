@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --primary: #f9451b;
  }

  html,
  body {
    font-family:
      'Poppins',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen,
      Ubuntu,
      Cantarell,
      'Open Sans',
      'Helvetica Neue',
      sans-serif;
  }
}
